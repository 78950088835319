import React, { useEffect, useState } from "react";
import NewOrderIcon from "../../../assets/images/icon1.svg";
import PendingOrderIcon from "../../../assets/images/icon2.svg";
import DeletedIcon from "../../../assets/images/icon3.svg";
import AwaitingIcon from "../../../assets/images/icon4.svg";
import OrderHistoryIcon from "../../../assets/images/icon5.svg";
import ContactCustomerIcon from "../../../assets/images/icon6.svg";
import ManageTeamIcon from "../../../assets/images/icon7.svg";
import GateWayIcon from "../../../assets/images/icon8.svg";
import ChangePasswordIcon from "../../../assets/images/icon10.svg";
import LogoutIcon from "../../../assets/images/icon11.svg";
import CompletedOrderIcon from "../../../assets/images/checklist.png";
import { StyledSidebar, StyledAdminMenu } from "./style";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cleanOrdersList, getAllFolderCount, toggleSidebar } from "../../../features/order/orderSlice";
import { logout } from "../../../features/user/authSlice";
import { FaTimes } from "react-icons/fa";
import jwt from "jwt-decode";

const AdminSidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("user");
    const count = useSelector((state) => state?.order?.count);
    const showSideBar = useSelector((state) => state?.order?.showSideBar);
    const [isActive, setIsActive] = useState(false);
    const location = useLocation();

    useEffect(() => {
        dispatch(getAllFolderCount());
        dispatch(cleanOrdersList());
    }, [location?.pathname]);

    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem("user");
        dispatch(logout());
        navigate("/");
    };

    let userType;
    if (token !== null) {
        const decodedToken = jwt(token);
        userType = decodedToken?.user?.member_role_id;
    }

    return (
        <StyledSidebar>
            <div className={showSideBar ? "sidebar open" : "sidebar"}>
                <StyledAdminMenu>
                    <button type="button" onClick={() => { dispatch(toggleSidebar); }} className="close-menu">
                        <FaTimes />
                    </button>
                    <ul>
                        {userType !== 4 && (
                            <>
                                {/* <li className={isActive === "recent" ? "active" : null}>
                                    <NavLink to="/admin/recent-order" onClick={() => setIsActive("recent")}>
                                        <span className="side-icon">
                                            <img src={NewOrderIcon} alt="NewOrderIcon" />
                                        </span>
                                        New Orders <span className="count">{count?.newOrdersCount}</span>
                                    </NavLink>
                                </li> */}

                                {/* <li className={isActive === "priority" ? "active" : null}>
                                    <NavLink to="/admin/priority-order" onClick={() => setIsActive("priority")}>
                                        <span className="side-icon">
                                            <img src={NewOrderIcon} alt="priorityOrderIcon" />
                                        </span>
                                        Priority Orders <span className="count">{count?.priorityOrdersCount}</span>
                                    </NavLink>
                                </li> */}
                                {/* <li className={isActive === "pending" ? "active" : null}>
                                    <NavLink to="/admin/pending-order" onClick={() => setIsActive("pending")}>
                                        <span className="side-icon">
                                            <img src={PendingOrderIcon} alt="PendingOrderIcon" />
                                        </span>
                                        Pending Orders <span className="count">{count?.pendingOrdersCount}</span>
                                    </NavLink>
                                </li> */}
                                {/* <li className={isActive === "completed" ? "active" : null}>
                                    <NavLink to="/admin/completed-order" onClick={() => setIsActive("completed")}>
                                        <span className="side-icon">
                                            <img src={CompletedOrderIcon} alt="CompletedOrderIcon" />
                                        </span>
                                        Completed Orders <span className="count">{count?.completedOrdersCount}</span>
                                    </NavLink>
                                </li> */}
                                <li className={isActive === "archive" ? "active" : null}>
                                    <NavLink to="/admin/deleted-order" onClick={() => setIsActive("archive")}>
                                        <span className="side-icon">
                                            <img src={DeletedIcon} alt="DeletedIcon" />
                                        </span>
                                        Deleted Orders <span className="count">{count?.deletedOrdersCount}</span>
                                    </NavLink>
                                </li>
                            </>
                        )}
                        <li className={isActive === "awaiting" ? "active" : null}>
                            <NavLink to="/admin/awaiting-response" onClick={() => setIsActive("awaiting")}>
                                <span className="side-icon">
                                    <img src={AwaitingIcon} alt="AwaitingIcon" />
                                </span>
                                Awaiting Customer <span className="count">{count?.awaitingOrdersCount}</span>
                            </NavLink>
                        </li>
                        <li className={isActive === "awaitinggovt" ? "active" : null}>
                            <NavLink to="/admin/awaiting-govt" onClick={() => setIsActive("awaitinggovt")}>
                                <span className="side-icon">
                                    <img src={AwaitingIcon} alt="AwaitingIcon" />
                                </span>
                                Awaiting Govt <span className="count">{count?.awaitingGovtOrdersCount}</span>
                            </NavLink>
                        </li>
                        <li className={isActive === "customer" ? "active" : null}>
                            <NavLink to="/admin/called" onClick={() => setIsActive("customer")}>
                                <span className="side-icon">
                                    <img src={AwaitingIcon} alt="AwaitingIcon" />
                                </span>
                                Customer Called <span className="count">{count?.contactCallOrdersCount}</span>
                            </NavLink>
                        </li>
                        <li className={isActive === "reject" ? "active" : null}>
                            <NavLink to="/admin/reject-order" onClick={() => setIsActive("reject")}>
                                <span className="side-icon">
                                    <img src={AwaitingIcon} alt="RejectIcon" />
                                </span>
                                 Rejected Order<span className="count">{count?.rejectOrdersCount}</span>
                            </NavLink>
                        </li>
                        {userType !== 4 && (
                            <li className={isActive === "allorder" ? "active" : null}>
                                <NavLink to="/admin/order-history" onClick={() => setIsActive("allorder")}>
                                    <span className="side-icon">
                                        <img src={OrderHistoryIcon} alt="OrderHistoryIcon" />
                                    </span>
                                    Order History <span className="count">{count?.allOrdersCount}</span>
                                </NavLink>
                            </li>
                        )}
                        {/* <li className={isActive === "phone" ? "active" : null}>
                            <NavLink to="/admin/contact-customer" onClick={() => setIsActive("phone")}>
                                <span className="side-icon">
                                    <img src={ContactCustomerIcon} alt="ContactCustomerIcon" />
                                </span>
                                Contact Customer <span className="count">{count?.contactCustomerOrdersCount}</span>
                            </NavLink>
                        </li> */}
                        <li className={isActive === "refund" ? "active" : null}>
                            <NavLink to="/admin/refund-order" onClick={() => setIsActive("refund")}>
                                <span className="side-icon">
                                    <img src={NewOrderIcon} alt="NewOrderIcon" />
                                </span>
                                Refunds <span className="count">{count?.refundOrdersCount}</span>
                            </NavLink>
                        </li>
                        { (userType === 1 || userType === 2) && (
                        <li className={isActive === "manage-team" ? "active" : null}>
                            <NavLink to="/admin/manage-team" onClick={() => setIsActive("manage-team")}>
                                <span className="side-icon">
                                    <img src={ManageTeamIcon} alt="ManageTeamIcon" />
                                </span>
                                Manage Team
                            </NavLink>
                        </li>
                        )}

                        { (userType === 1 || userType === 2) && (
                        <li className={isActive === "setting" ? "active" : null}>
                            <NavLink to="/admin/gateway-setting" onClick={() => setIsActive("setting")}>
                                <span className="side-icon">
                                    <img src={GateWayIcon} alt="GateWayIcon" />
                                </span>
                                Gateway Setting
                            </NavLink>
                        </li>
                        )}
                        <li className={isActive === "change-password" ? "active" : null}>
                            <NavLink to="/admin/change-password" onClick={() => setIsActive("change-password")}>
                                <span className="side-icon">
                                    <img src={ChangePasswordIcon} alt="ChangePasswordIcon" />
                                </span>
                                Change Password
                            </NavLink>
                        </li>
                        <li className={isActive === "logout" ? "active" : null}>
                            <NavLink to="logout" onClick={handleLogout}>
                                <span className="side-icon">
                                    <img src={LogoutIcon} alt="LogoutIcon" />
                                </span>
                                Logout
                            </NavLink>
                        </li>
                    </ul>
                </StyledAdminMenu>
            </div>
        </StyledSidebar>
    );
};

export default AdminSidebar;
